// import service from "@/store/services/csms-charge-stations-service";
import service from "@/helpers/service";
const state = {
  loading: false,
  meta: {},
  list: [],
  //
  unassignMembers: [],
};

const mutations = {
  SET_META(state, data) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    }
  },
  SET_LIST(state, payload) {
    state.list = payload;
  },

  // ADD_LIST(state, mdMake) {
  //   state.list.push(mdMake);
  // },

  UPDATE_LIST(state, payload) {
    const item = state.list.find((item) => item.vehicle_id === payload.vehicle);
    const params = { ...item };
    params.fleet_staff_assignment = payload.fleet;
    Object.assign(item, params);
  },

  DELETE_LIST(state, payload) {
    state.list.splice(state.list.map((o) => o.id).indexOf(payload.id), 1);
  },
  SET_UNASSIGN_MEMBERS(state, payload) {
    state.unassignMembers = payload;
  },
};

const actions = {
  list: (store, params) => {
    store.state.loading = true;
    service
      .get("staff-management/fleet-staff-assignment", params)
      .then((data) => {
        store.commit("SET_META", data.body);
        store.commit("SET_LIST", data.body.data);
        return data.body.data;
      })
      .finally(() => (store.state.loading = false));
  },

  add: (store, { payload, filter }) => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    return service
      .post("staff-management/fleet-staff-assignment", payload, config)
      .then(() => {
        // store.commit("ADD_LIST", data.body);
        store.dispatch("list", filter);
      });
  },

  assign: (store, payload) => {
    return service
      .post("staff-management/fleet-staff-assignment", payload)
      .then((data) => {
        const params = {
          vehicle: payload.vehicle,
          fleet: data.body,
        };
        store.commit("UPDATE_LIST", params);
        // store.dispatch("list", filter);
      });
  },

  destroy: (store, params) => {
    return service
      .destroy("staff-management/fleet-staff-assignment", params)
      .then(() => {
        // store.commit("DELETE_LIST", params);
      });
  },
  //
  unassignMembers: (store, params) => {
    store.state.loading = true;
    return service
      .get("staff-management/unassigned-staff-members", params)
      .then((data) => {
        store.commit("SET_UNASSIGN_MEMBERS", data.body);
        return data.body;
      })
      .finally(() => (store.state.loading = false));
  },
};

const getters = {
  getLoading(state) {
    return state.loading;
  },
  getMeta(state) {
    return state.meta;
  },
  getList(state) {
    return state.list;
  },
  //
  getUnassignMembers(state) {
    return state.unassignMembers;
  },
};

const drivingStaff = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default drivingStaff;
