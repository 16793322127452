import axios from "@/helpers/axios";

// Call APIs from Python
import service from "@/helpers/service";

const base_url = process.env.VUE_APP_API_BASEURL;

async function list(params) {
  return service.get("/vehicle/telematic-details", params).then((response) => {
    return response;
  });
  // return axios
  //   .get(`${base_url}telematic-details`, { params })
  //   .then((response) => {
  //     return response.data;
  //   });
}
async function OEMlist(params) {
  return service
    .get("/vehicle/oem-telematic-details", params)
    .then((response) => {
      return response;
    });
  // return axios
  //   .get(`${base_url}oem-telematic-details`, { params })
  //   .then((response) => {
  //     return response.data;
  //   });
}

async function commandsList(imei, params) {
  return axios
    .get(`${base_url}flespi-commands/${imei}`, { params })
    .then((response) => {
      return response.data;
    });
}

function show(id) {
  return service.get(`vehicle/telematic-details/${id}`).then((response) => {
    return response;
  });
  // return axios.get(`${base_url}telematic-details/${id}`).then((response) => {
  //   return response.data;
  // });
}

async function getHeadersList(clientId) {
  return axios
    .get(`${base_url}telematic-details/${clientId}/headers-list`)
    .then((response) => {
      return response.data;
    });
}

async function vehicleTelematicDashboard(params) {
  return service.get("/vehicle/vehicle-dashboard", params).then((response) => {
    return response;
  });
  // return axios
  //   .get(`${base_url}vehicle-dashboard`, { params })
  //   .then((response) => {
  //     return response.data;
  //   });
}

export default {
  list,
  OEMlist,
  getHeadersList,
  commandsList,
  show,
  vehicleTelematicDashboard,
};
